<template>
	<div class="dashboard-container overflow-scroll width-100" :ref="'dashboardPanelContent-' + type">
		<dashboard-content-index :type="type" :key="'sla-global-' + type"></dashboard-content-index>
	</div>
</template>

<script>
import DashboardContentIndex from './content/DashboardContentIndex';
//import DashboardSlaFrecuency from './content/DashboardSlaFrecuency.vue';
export default {
	components: {
		DashboardContentIndex
		//DashboardSlaFrecuency
	},
	props: {
		type: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			//tab: null,
			loadingAnimation: true,
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		tab: {
			get() {
				return this.$store.getters.getContentTab;
			},
			set(value) {
				this.$store.commit('setContentTab', value);
			}
		}
	},
	watch: {}
};
</script>

<style></style>
