<template>
	<div class="kpi-bar d-flex justify-start" :style="{ height: barHeight }">
		<kpi-bar-section
			v-for="(section, i) in sections"
			:key="i"
			:value="section.value"
			@click="section.click"
			:color="section.color"
			:disabled="section.disabled"
			:title="section.title"
			:percent="section.percent"
			:barHeight="barHeight"
		></kpi-bar-section>
	</div>
</template>

<script>
import KpiBarSection from './KpiBarSection';
export default {
	components: {
		KpiBarSection
	},
	props: {
		sections: {
			type: Array,
			required: true
		},
		/* total: {
			type: Number,
			required: true
		},
		percentage: {
			type: Number,
			required: true
		}, */
		barHeight: {
			default: '6px'
		}
	},
	watch: {
		sections: {
			handler(newValue, oldValue) {
				console.log('sections watcher', oldValue, newValue);
			},
			deep: true
		}
	}
};
</script>

<style lang="postcss">
@import '../../../styles/eiffel-variables.pcss';

.kpi-bar {
	width: 100%;
	background: rgb(228, 228, 228);
	border-radius: 2px;
	margin-top: 3px;
	overflow: hidden;
}
</style>
