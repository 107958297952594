<template>
	<div class="sla-global-content pa-2 height-100 width-100 overflow-scroll">
		<v-form ref="dashboardForm" class="responsive-height" style="overflow: hidden scroll">
			<v-row no-gutters class="justify-end align-center">
				<v-col v-if="showAdvancedDate" cols="12" md="4">
					<pui-date-field
						:label="$t('aqi.startDate')"
						toplabel
						:required="!form.startDateDisabled"
						v-model="form.startDate"
						:disabled="form.startDateDisabled"
						:min="firstDateSLA"
						:max="lastDateSLA || Date.now()"
						:tooltipDescription="form.infoStartDate"
						@input="inputStartDate"
					></pui-date-field>
				</v-col>
				<v-col v-if="showAdvancedDate" cols="12" md="4">
					<pui-date-field
						:label="$t('aqi.endDate')"
						toplabel
						:required="!form.endDateDisabled"
						v-model="form.endDate"
						:disabled="form.endDateDisabled"
						:min="form.startDate"
						:max="lastDateSLA || Date.now()"
						:tooltipDescription="form.infoEndDate"
						@input="submitEndDate"
					></pui-date-field>
				</v-col>
				<v-col cols="12" :md="showAdvancedDate ? 4 : 6" class="d-flex justify-start align-center" :class="{ 'mt-n6': showAdvancedDate }">
					<v-select
						:label="$t('aqi.dateInterval')"
						toplabel
						clearable
						required
						hide-details
						:disabled="form.formDisabled"
						v-model="form.dateInterval"
						:items="intervals"
						@input="submitDateInterval"
						class="form-control-select px-1"
					></v-select>
					<v-btn :color="primaryColor" @click="resendQuery" title="Refresh" class="mt-4 pa-0" height="39" elevation="0" min-width="42">
						<v-icon size="22" color="white">fak fa-line-refresh</v-icon>
					</v-btn>
					<echart-export-excel
						v-if="form.option"
						:option="form.option"
						:designOption="form.designOption"
						:dateInterval="form.dateInterval ? form.dateInterval : [form.startDate, form.endDate]"
						:bgColor="primaryColor"
						iconColor="white"
						:height="39"
						spacingClass="mt-4 ml-1 pa-0"
						:chartInstance="chartInstance"
					></echart-export-excel>
				</v-col>
			</v-row>
		</v-form>
		<v-row class="py-1 px-1" dense>
			<!-- SLA KPI -->
			<v-col cols="12" sm="12" md="6" lg="3" xl="3">
				<!-- <dashboard-card @click="activateGlobalChart"></dashboard-card> -->
				<v-hover v-slot="{ hover }">
					<v-card
						class="pa-2 dynamic-btn pointer"
						@click="activateGlobalChart"
						:disabled="false"
						:color="hover || activeGlobal ? primaryColor : '#D7EBFF'"
					>
						<v-row no-gutters>
							<v-col cols="3" class="d-flex align-center justify-center">
								<v-card flat :ripple="false" class="d-flex flex-column align-center px-5 py-2" :disabled="false" color="transparent">
									<img
										src="../../../assets/images/sla-global-icon.png"
										width="64"
										alt="SLA Global"
										:class="{ 'color-white': hover || activeGlobal }"
									/>
								</v-card>
							</v-col>
							<v-col cols="9" class="pl-2">
								<v-card flat color="transparent" class="d-flex flex-column align-start justify-center pl-3">
									<v-card-title class="pa-0 fs-regular" :class="hover || activeGlobal ? 'color-white' : 'color-black'">{{
										$t('panels.dashboard.sla-global')
									}}</v-card-title>
									<v-card-subtitle class="pa-0 pt-3 d-flex flex-column align-start">
										<span class="fs-lg fw-700" :class="hover || activeGlobal ? 'color-white' : 'color-primary'">
											{{ (globalOk > 0 ? ((globalOk / (globalOk + globalError)) * 100).toFixed(2) : 0) + '%' }}
										</span>
										<span class="fs-sm fw-regular" :class="hover || activeGlobal ? 'color-white' : 'color-high'">{{
											globalOk + '/' + (globalOk + globalError)
										}}</span>
									</v-card-subtitle>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
				</v-hover>
			</v-col>
			<!-- WARNING KPI -->
			<v-col cols="12" sm="12" md="6" lg="3" xl="3">
				<v-hover v-slot="{ hover }">
					<v-card
						class="pa-2 dynamic-btn pointer"
						@click="activateWarningPie"
						:disabled="false"
						:color="hover || activeWarning ? primaryColor : '#D7EBFF'"
					>
						<v-row no-gutters>
							<v-col cols="3" class="d-flex align-center justify-center">
								<v-card flat :ripple="false" class="d-flex flex-column align-center px-5 py-2" :disabled="false" color="transparent">
									<img
										src="../../../assets/images/sla-warning-icon.png"
										width="64"
										alt="Warning"
										:class="{ 'color-white': hover || activeGlobal }"
									/>
								</v-card>
							</v-col>
							<v-col cols="9" class="pl-2">
								<v-card flat color="transparent" class="d-flex flex-column align-start justify-center pl-3">
									<v-card-title class="pa-0 fs-regular" :class="hover || activeWarning ? 'color-white' : 'color-black'">
										{{ $t('panels.dashboard.sla-warning-type') }}
									</v-card-title>
									<v-card-subtitle class="pa-0 pt-3 d-flex flex-column align-start">
										<span class="fs-lg fw-700" :class="hover || activeWarning ? 'color-white' : 'color-primary'">{{
											(globalWarning > 0 ? ((globalWarning / globalOk) * 100).toFixed(2) : 0) + '%'
										}}</span>
										<span class="fs-sm fw-regular" :class="hover || activeWarning ? 'color-white' : 'color-high'">
											{{ globalWarning + '/' + globalOk }}
										</span>
									</v-card-subtitle>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
				</v-hover>
			</v-col>
			<!-- FREQUENCY KPI -->
			<v-col cols="12" sm="12" md="6" lg="3" xl="3">
				<v-hover v-slot="{ hover }">
					<v-card
						class="pa-2 dynamic-btn pointer"
						@click="activateFrequencyChart"
						:disabled="false"
						:color="hover || activeFrequency ? primaryColor : '#D7EBFF'"
					>
						<v-row no-gutters>
							<v-col cols="3" class="d-flex align-center justify-center">
								<v-card flat :ripple="false" class="d-flex flex-column align-center px-5 py-2" :disabled="false" color="transparent">
									<img
										src="../../../assets/images/sla-frequency-icon.png"
										width="64"
										alt="Frequency"
										:class="{ 'color-white': hover || activeFrequency }"
									/>
								</v-card>
							</v-col>
							<v-col cols="9" class="pl-2">
								<v-card flat color="transparent" class="d-flex flex-column align-start justify-center pl-3">
									<v-card-title class="pa-0 fs-regular" :class="hover || activeFrequency ? 'color-white' : 'color-black'">
										{{ $t('panels.dashboard.sla-expected-frequency') }}
									</v-card-title>
									<v-card-subtitle class="pa-0 pt-3 d-flex flex-column align-start">
										<span class="fs-lg fw-700" :class="hover || activeFrequency ? 'color-white' : 'color-primary'">{{
											(globalExpected > 0 ? (((globalOk + globalError) / globalExpected) * 100).toFixed(2) : 0) + '%'
										}}</span>
										<span class="fs-sm fw-regular" :class="hover || activeFrequency ? 'color-white' : 'color-high'">
											{{ globalOk + globalError + '/' + globalExpected }}
										</span>
									</v-card-subtitle>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
				</v-hover>
			</v-col>
			<!-- QUALITY KPI -->
			<v-col cols="12" sm="12" md="6" lg="3" xl="3">
				<v-hover v-slot="{ hover }">
					<v-card
						class="pa-2 dynamic-btn pointer"
						@click="activateQualityChart"
						:disabled="false"
						:color="hover || activeQuality ? primaryColor : '#D7EBFF'"
					>
						<v-row no-gutters>
							<v-col cols="3" class="d-flex align-center justify-center">
								<v-card flat :ripple="false" class="d-flex flex-column align-center px-5 py-2" :disabled="false" color="transparent">
									<img
										src="../../../assets/images/sla-quality-icon.png"
										width="64"
										alt="Quality"
										:class="{ 'color-white': hover || activeGlobal }"
									/>
								</v-card>
							</v-col>
							<v-col cols="9" class="pl-2">
								<v-card flat color="transparent" class="d-flex flex-column align-start justify-center pl-3">
									<v-card-title class="pa-0 fs-regular" :class="hover || activeQuality ? 'color-white' : 'color-black'">
										{{ $t('panels.dashboard.sla-quality') }}
									</v-card-title>
									<v-card-subtitle class="pa-0 pt-3 d-flex flex-column align-start">
										<span class="fs-lg fw-700" :class="hover || activeQuality ? 'color-white' : 'color-primary'">{{
											(globalOk > 0 ? ((globalError / (globalOk + globalError)) * 100).toFixed(2) : 0) + '%'
										}}</span>
										<span class="fs-sm fw-regular" :class="hover || activeQuality ? 'color-white' : 'color-high'">
											{{ globalError + '/' + (globalOk + globalError) }}
										</span>
									</v-card-subtitle>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
				</v-hover>
			</v-col>
		</v-row>

		<!-- ECHARTS -->
		<v-row no-gutters class="overflow-scroll" v-if="contentTab == globalTab">
			<v-tabs height="30" v-model="globalFilterTab" :color="primaryColor" background-color="rgba(0, 0, 0, 0)" show-arrows>
				<v-tab ref="filter-global" href="#filter-global">{{ $t('panels.dashboard.sla-global-card') }}</v-tab>
				<v-tab ref="filter-area" href="#filter-area">{{ $t('panels.dashboard.sla-area') }}</v-tab>
				<v-tab ref="filter-datasource" href="#filter-datasource">{{ $t('panels.dashboard.sla-datasource') }}</v-tab>
				<v-tab ref="filter-device" href="#filter-device">{{ $t('panels.dashboard.sla-device') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="globalFilterTab" class="width-100">
				<v-tab-item value="filter-global" class="width-100">
					<v-col cols="12">
						<pui-field-set :title="$t('panels.dashboard.sla-global')">
							<v-chart
								:loading="loadingAnimation"
								v-if="slaData"
								class="chart-with-tabs"
								:option="initChart(slaData)"
								autoresize
								ref="chart-sla-global"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
				<v-tab-item value="filter-area" class="width-100">
					<v-col cols="12">
						<pui-field-set title="Area SLA Chart">
							<v-chart
								v-if="areaChartData"
								:loading="loadingAnimation"
								class="chart-with-tabs"
								:option="initChartGroup(areaChartData, 'area')"
								autoresize
								ref="chart-sla-area"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
				<v-tab-item value="filter-datasource" class="width-100">
					<v-col cols="12">
						<pui-field-set title="Datasource SLA Chart">
							<v-chart
								v-if="datasourceChartData"
								:loading="loadingAnimation"
								class="chart-with-tabs"
								:option="initChartGroup(datasourceChartData, 'datasource')"
								autoresize
								ref="chart-sla-datasource"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
				<v-tab-item value="filter-device" class="width-100">
					<v-col cols="12">
						<pui-field-set title="Devices SLA Chart">
							<v-chart
								v-if="deviceChartData"
								:loading="loadingAnimation"
								class="chart-with-tabs"
								:option="initChartDevice(deviceChartData)"
								autoresize
								ref="chart-sla-device"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
			</v-tabs-items>
		</v-row>
		<v-row no-gutters class="overflow-scroll" v-else-if="contentTab == warningTab">
			<v-tabs height="30" v-model="warningFilterTab" :color="primaryColor" background-color="rgba(0, 0, 0, 0)" show-arrows>
				<v-tab ref="filter-warning" href="#filter-warning">{{ $t('panels.dashboard.sla-warning') }}</v-tab>
				<v-tab ref="filter-warning-types" href="#filter-warning-types">{{ $t('panels.dashboard.sla-warning-type') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="warningFilterTab" class="width-100">
				<v-tab-item value="filter-warning" class="width-100">
					<v-col cols="12">
						<pui-field-set :title="$t('panels.dashboard.sla-warning')">
							<v-chart
								:loading="loadingAnimation"
								class="chart-with-tabs"
								:option="initChartWarning(slaData)"
								autoresize
								ref="chart-warning"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
				<v-tab-item value="filter-warning-types" class="width-100">
					<v-col cols="12">
						<pui-field-set :title="$t('panels.dashboard.sla-warning-type')">
							<v-chart
								:loading="loadingAnimation"
								class="chart-with-tabs"
								:option="initChartWarningType(slaData)"
								autoresize
								ref="chart-warning-type"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
			</v-tabs-items>
		</v-row>
		<v-row no-gutters class="overflow-scroll" v-else-if="contentTab == frequencyTab">
			<v-tabs height="30" v-model="frequencyFilterTab" :color="primaryColor" background-color="rgba(0, 0, 0, 0)" show-arrows>
				<v-tab ref="frequency-global" href="#frequency-global">{{ $t('panels.dashboard.sla-frequency-global') }}</v-tab>
				<v-tab ref="frequency-area" href="#frequency-area">{{ $t('panels.dashboard.sla-frequency-area') }}</v-tab>
				<v-tab ref="frequency-datasource" href="#frequency-datasource">{{ $t('panels.dashboard.sla-frequency-datasource') }}</v-tab>
				<v-tab ref="frequency-device" href="#frequency-device">{{ $t('panels.dashboard.sla-frequency-device') }}</v-tab>
				<v-tab ref="frequency-analysis" href="#frequency-analysis">{{ $t('panels.dashboard.sla-frequency-analysis') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="frequencyFilterTab" class="width-100">
				<v-tab-item value="frequency-global" class="width-100 height-100">
					<v-col cols="12">
						<pui-field-set :title="globalFrequencyTitle ?? $t('panels.dashboard.sla-frequency-global')">
							<v-chart
								:loading="loadingAnimation"
								class="chart-with-tabs"
								:option="initChartFrequency(slaData)"
								autoresize
								ref="chart-frequency"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
				<v-tab-item value="frequency-area" class="width-100 height-100">
					<v-col cols="12">
						<v-col cols="6" class="pa-0">
							<pui-select
								:label="$t('panels.dashboard.sla-frequency-area')"
								:placeholder="$t('panels.dashboard.sla-frequency-area')"
								singleItemAutoselect
								hideDetails
								toplabel
								v-model="selectedArea"
								:items="areaItems"
								itemValue="value"
								itemText="text"
							></pui-select>
						</v-col>
						<pui-field-set :title="areaFrequencyTitle ?? $t('panels.dashboard.sla-frequency-area')">
							<v-chart
								v-if="selectedArea"
								:loading="loadingAnimation"
								class="chart-with-tabs-plus"
								:option="
									initChartFrequencyArea(
										areaChartData.find((area) => area.pmareaid == selectedArea.value),
										$store.getters.getAreas.find((area) => area.pmareaid == selectedArea.value)
									)
								"
								autoresize
								ref="chart-frequency-area"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
				<v-tab-item value="frequency-datasource" class="width-100">
					<v-col cols="12">
						<v-col cols="6" class="pa-0">
							<pui-select
								:label="$t('panels.dashboard.sla-frequency-datasource')"
								:placeholder="$t('panels.dashboard.sla-frequency-datasource')"
								v-model="selectedDatasource"
								singleItemAutoselect
								hideDetails
								toplabel
								:items="datasourceItems"
								itemValue="value"
								itemText="text"
							></pui-select>
						</v-col>
						<pui-field-set :title="datasourceFrequencyTitle ?? $t('panels.dashboard.sla-frequency-datasource')">
							<v-chart
								v-if="selectedDatasource"
								:loading="loadingAnimation"
								class="chart-with-tabs-plus"
								:option="
									initChartFrequencyDatasource(
										datasourceChartData.find((datasource) => datasource.pmdatasourcetypeid == selectedDatasource.value),
										$store.getters.getDatasourcetype.find(
											(datasource) => datasource.pmdatasourcetypeid == selectedDatasource.value
										)
									)
								"
								autoresize
								ref="chart-frequency-datasource"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
				<v-tab-item value="frequency-device" class="width-100">
					<v-col cols="12">
						<v-row no-gutters>
							<v-col cols="6" class="pa-0">
								<pui-select
									:label="$t('panels.dashboard.sla-frequency-datasource')"
									:placeholder="$t('panels.dashboard.sla-frequency-datasource')"
									v-model="selectedDatasourceDevice"
									singleItemAutoselect
									hideDetails
									toplabel
									clearable
									:items="datasourceItems"
									itemValue="value"
									itemText="text"
								></pui-select>
							</v-col>
							<v-col class="d-flex justify-start align-center pa-0">
								<v-autocomplete
									:label="$t('panels.dashboard.sla-frequency-device')"
									toplabel
									auto-select-first
									hide-details
									:disabled="!selectedDatasourceDevice"
									:items="deviceItems"
									return-object
									v-model="selectedDevice"
									class="form-control-select px-1"
								></v-autocomplete>
							</v-col>
						</v-row>

						<pui-field-set :title="deviceFrequencyTitle ?? $t('panels.dashboard.sla-frequency-device')">
							<v-chart
								v-if="selectedDevice"
								:loading="loadingAnimation"
								class="chart-with-tabs-plus"
								:option="
									initChartFrequencyDevice(
										deviceChartData.find((device) => device.pmdatasourceinstanceid == selectedDevice.value),
										$store.getters.getDevicesData.find((device) => device.pmdatasourceinstanceid == selectedDevice.value)
									)
								"
								autoresize
								ref="chart-frequency-device"
							></v-chart>
						</pui-field-set>
					</v-col>
				</v-tab-item>
				<v-tab-item value="frequency-analysis" class="width-100">
					<v-col cols="12" class="pb-0">
						<v-card class="mt-2">
							<v-card-title class="px-3 pt-1">{{ $t('panels.dashboard.lowest-frequency-title') }}</v-card-title>
							<v-data-table
								height="50%"
								:headers="getHeaders()"
								:items="tableDataLowest ?? []"
								:items-per-page="5"
								:loading="loadingAnimation"
								dense
							>
								<template v-slot:item.devicecode="{ item }">
									<span class="fw-500">
										{{ item.devicecode }}
									</span>
								</template>
								<template v-slot:item.datetimestamp="{ item }">
									<span>
										{{ formatDate(item.datetimestamp) }}
									</span>
								</template>
							</v-data-table>
						</v-card>
						<v-card class="mt-3">
							<v-card-title class="px-3 pt-1">{{ $t('panels.dashboard.highest-frequency-title') }}</v-card-title>
							<v-data-table
								height="50%"
								:headers="getHeaders()"
								:items="tableDataHighest ?? []"
								:items-per-page="5"
								:loading="loadingAnimation"
								dense
							>
								<template v-slot:item.devicecode="{ item }">
									<span class="fw-500">
										{{ item.devicecode }}
									</span>
								</template>
								<template v-slot:item.datetimestamp="{ item }">
									<span>
										{{ formatDate(item.datetimestamp) }}
									</span>
								</template>
							</v-data-table>
						</v-card>
					</v-col>
				</v-tab-item>
			</v-tabs-items>
		</v-row>
		<v-row no-gutters class="overflow-scroll" v-else-if="contentTab == qualityTab">
			<v-col cols="12">
				<pui-field-set :title="$t('panels.dashboard.sla-error-type')">
					<v-chart
						v-if="errorTypesData"
						:loading="loadingQuality"
						class="chart-no-tabs"
						:option="initChartDataQuality(errorTypesData)"
						autoresize
						ref="chart-error-type"
					></v-chart>
					<div v-else-if="!errorTypesData && loadingQuality" class="d-flex justify-center align-center chart-no-tabs">
						<v-progress-circular indeterminate :color="primaryColor"></v-progress-circular>
					</div>
				</pui-field-set>
			</v-col>
		</v-row>
	</div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { search, getDateByFormat, isSuperAdmin } from '@/api/common';
import EchartExportExcel from '../../echart/functionalities/EchartExportExcel.vue';
import constants from '@/utils/constants';
import DashboardContentMixin from '@/mixins/DashboardContentMixin';
//import DashboardContentChart from './DashboardContentChart';
import VChart from 'vue-echarts';
import { getErrorTypes } from '@/api/databases_API';
import { obtainIndexName } from '@/api/common';
import { query_errorTypes } from '@/utils/queries';

export default {
	mixins: [DashboardContentMixin],
	components: {
		//DashboardContentChart,
		VChart,
		EchartExportExcel
	},
	props: {
		type: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			errorTypesData: null,
			chartRef: null,
			chartInstance: null,
			showExportExcel: true,
			tableDataLowest: null,
			tableDataHighest: null,
			form: {
				dateInterval: null,
				startDate: null,
				endDate: null,
				formDisabled: false,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				designOption: {
					id: constants.VISUALIZATIONS.TIME_SERIES,
					name: 'DASHBOARD'
				},
				configurations: [],
				option: null
			},
			intervals: [
				/* { text: this.$t('aqi.chooseInterval'), value: '0' }, */
				/* { text: this.$t('aqi.last24h'), value: 1 },
				{ text: this.$t('aqi.last48h'), value: 2 }, */
				{ text: this.$t('aqi.lastWeek'), value: 7 },
				{ text: this.$t('aqi.last15D'), value: 15 },
				{ text: this.$t('aqi.lastMonth'), value: 30 },
				{ text: this.$t('aqi.customInterval'), value: -1 }
			],

			loadingAnimation: false,
			loadingQuality: false,
			showAdvancedDate: false,
			firstDateSLA: null,
			lastDateSLA: null,
			primaryColor: this.$store.state.global.primaryColor,
			/* counters */
			globalOk: 0,
			globalWarning: 0,
			globalError: 0,
			globalExpected: 0,
			/* activation */
			activeGlobal: true,
			activeWarning: false,
			activeFrequency: false,
			activeQuality: false,
			/* tabs stuff */
			globalTab: 'sla-global',
			warningTab: 'sla-warning',
			frequencyTab: 'sla-frequency',
			qualityTab: 'sla-quality',
			globalFilterTab: null,
			frequencyFilterTab: null,
			warningFilterTab: null,
			/* chart data stores */
			slaData: null,
			areaChartData: null,
			datasourceChartData: null,
			deviceChartData: null,
			// Frequency filters
			filterArea: null,
			filterDatasource: null,
			filterDatasourceDevice: null,
			filterDevice: null,
			globalFrequencyTitle: null,
			areaFrequencyTitle: null,
			datasourceFrequencyTitle: null,
			deviceFrequencyTitle: null,
			deviceFixedFilter: {}
		};
	},
	computed: {
		dashboardTab() {
			return this.$store.getters.getDashboardTab;
		},
		contentTab: {
			set(value) {
				this.$store.commit('setContentTab', value);
			},
			get() {
				return this.$store.getters.getContentTab;
			}
		},
		slaChartFilter() {
			return this.$store.getters.getSlaChartFilter;
		},
		onlyOneArea() {
			return this.type == 'area' && this.$store.getters.getAreas.length == 1;
		},
		areaItems() {
			return this.$store.getters.getAreas.map((area) => {
				return { text: area.name, value: area.pmareaid };
			});
		},
		datasourceItems() {
			return this.$store.getters.getDatasourcetype.map((datasource) => {
				return { text: datasource.name, value: datasource.pmdatasourcetypeid };
			});
		},
		deviceItems() {
			if (this.selectedDatasourceDevice) {
				return this.$store.getters.getDevicesData
					.filter((device) => device.pmdatasourcetypeid == this.selectedDatasourceDevice.value)
					.map((device) => {
						return { text: device.name + ' (' + device.code + ')', value: device.pmdatasourceinstanceid };
					});
			} else return [];
		},
		selectedArea: {
			get() {
				if (this.filterArea) return this.filterArea;
				if (this.areaItems.length == 1) return this.areaItems[0];
				return null;
			},
			set(value) {
				this.filterArea = value;
			}
		},
		selectedDatasource: {
			get() {
				if (this.filterDatasource) return this.filterDatasource;
				if (this.datasourceItems.length == 1) return this.datasourceItems[0];
				return null;
			},
			set(value) {
				this.filterDatasource = value;
			}
		},
		selectedDatasourceDevice: {
			get() {
				if (this.filterDatasourceDevice) return this.filterDatasourceDevice;
				if (this.datasourceItems.length == 1) return this.datasourceItems[0];
				return null;
			},
			set(value) {
				this.filterDatasourceDevice = value;
			}
		},
		selectedDevice: {
			get() {
				if (this.filterDevice) return this.filterDevice;
				if (this.deviceItems.length == 1) return this.deviceItems[0];
				return null;
			},
			set(value) {
				this.filterDevice = value;
			}
		}
	},
	watch: {
		selectedArea(val, prev) {
			console.log('device area watcher', prev, val);
			if (val) this.form.option = this.getCurrentOption();
		},
		selectedDatasource(val, prev) {
			console.log('device dts watcher', prev, val);
			if (val) this.form.option = this.getCurrentOption();
		},
		selectedDevice(val, prev) {
			console.log('device dev watcher', prev, val);
			if (val) this.form.option = this.getCurrentOption();
		},
		selectedDatasourceDevice(val, prev) {
			console.log('watcher datasource filter', prev, val);
			this.selectedDevice = this.deviceFrequencyTitle = null;
		},
		'form.dateInterval': {
			handler(newValue, oldValue) {
				console.debug('date interval watcher', oldValue, newValue);
				if (!newValue) {
					this.loadingAnimation = false;
					this.showAdvancedDate = false;
				}
			}
		},
		dashboardTab: function (val, prev) {
			console.log('info: dashboardTab watcher', prev, val);
			this.form.option = this.getCurrentOption();
		},
		contentTab: function (val, prev) {
			console.log('info: contentTab watcher', prev, val);
			//this.resendQuery();
			if (val == this.frequencyTab && this.slaDataRaw) {
				this.tableDataLowest = this.formatTableData(this.slaDataRaw, 'asc');
				this.tableDataHighest = this.formatTableData(this.slaDataRaw, 'desc');
			} else {
				this.tableDataLowest = this.tableDataHighest = null;
			}
			this.form.option = this.getCurrentOption();
		},
		globalFilterTab: function (val, prev) {
			console.log('info: globalFilterTab watcher', prev, val);
			this.form.option = this.getCurrentOption();
		},
		frequencyFilterTab: function (val, prev) {
			console.log('info: frequencyFilterTab watcher', prev, val);
			this.form.option = this.getCurrentOption();
		},
		warningFilterTab: function (val, prev) {
			console.log('info: warningFilterTab watcher', prev, val);
			this.form.option = this.getCurrentOption();
		},
		slaChartFilter: {
			handler(newValue, oldValue) {
				console.log('sla filter device', oldValue, newValue, this.$refs);
				if (newValue && this.$refs['chart-sla-device'])
					this.$refs['chart-sla-device'].dispatchAction({
						type: 'highlight',
						seriesId: newValue.code + '_' + newValue.pmdatasourceinstanceid
					});
				else this.$refs['chart-sla-device'].dispatchAction({ type: 'highlight', seriesId: null });
			}
		}
	},
	created() {
		this.$store.commit('setContentTab', this.globalTab);
	},
	mounted() {
		this.formatIntervals();
		this.form.dateInterval = 7;
		this.submitDateInterval();
		this.globalFrequencyTitle = this.userProperties.organizationname;
	},
	beforeDestroy() {},
	methods: {
		submitDateInterval() {
			let d = new Date();
			console.log('log: submitDateInteval', d, this.form.dateInterval);
			if (this.form.dateInterval) {
				if (this.form.dateInterval == -1) {
					this.showAdvancedDate = true;
					this.form.startDateDisabled = false;
					this.form.infoStartDate = this.$t('aqi.infoStartDate');
					this.form.infoEndDate = this.$t('aqi.infoEndDate');
				} else {
					this.showAdvancedDate = false;
					this.loadingAnimation = true;
					this.form.startDateDisabled = true;
					this.form.startDate = this.form.endDate = null;
					this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');

					if (this.contentTab == this.frequencyTab) {
						this.tableDataLowest = [];
						this.tableDataHighest = [];
					}

					d.setUTCDate(d.getUTCDate() - this.form.dateInterval);
					d.setUTCHours(0, 0, 0, 0);

					this.getSLA(d.valueOf()).then((result) => {
						console.log('result get sla', result, this.groupByDate(result));
						if (result.length > 0) {
							if (this.contentTab == this.frequencyTab) {
								this.tableDataLowest = this.formatTableData(result, 'asc');
								this.tableDataHighest = this.formatTableData(result, 'desc');
							}
							let slaByArea = this.groupByArea(result);
							let slaByDatasource = this.groupByDatasource(result);
							let slaByDevice = this.groupByDevice(result);
							let slaByDate = this.groupByDate(result);

							let slaByAreaAndDate = [];
							let slaByDatasourceAndDate = [];
							let slaByDeviceAndDate = [];

							slaByArea.forEach((areaGroup) => {
								slaByAreaAndDate.push({
									pmareaid: areaGroup.pmareaid,
									areaname: areaGroup.areaname,
									group: this.groupByDate(areaGroup.group)
								});
							});

							slaByDatasource.forEach((datasourceGroup) => {
								slaByDatasourceAndDate.push({
									pmdatasourcetypeid: datasourceGroup.pmdatasourcetypeid,
									datasourcetypename: datasourceGroup.datasourcetypename,
									group: this.groupByDate(datasourceGroup.group)
								});
							});

							slaByDevice.forEach((deviceGroup) => {
								slaByDeviceAndDate.push({
									pmdatasourceinstanceid: deviceGroup.pmdatasourceinstanceid,
									devicecode: deviceGroup.devicecode,
									group: this.groupByDate(deviceGroup.group)
								});
							});
							this.slaDataRaw = result;
							this.slaData = slaByDate;
							this.deviceChartData = slaByDeviceAndDate;
							this.areaChartData = slaByAreaAndDate;
							this.datasourceChartData = slaByDatasourceAndDate;

							this.updateCounters(slaByDate);
							this.form.option = this.getCurrentOption();
							this.loadingAnimation = false;
							if (this.errorTypesData || this.contentTab == this.qualityTab) {
								this.loadingQuality = true;
								this.retrieveErrorTypes(
									query_errorTypes('validations', d.valueOf(), Date.now()),
									this.$store.getters.getDatasourcetype
								);
							}
						} else {
							this.$puiNotify.info(this.$t('common.apology'), this.$t('aqi.noDataCAMS'));
							this.loadingAnimation = false;
							this.slaDataRaw = this.slaData = this.deviceChartData = this.areaChartData = this.datasourceChartData = null;
						}
					});
				}
			} else {
				this.form.startDateDisabled = true;
				this.form.endDateDisabled = true;
				this.form.startDate = this.form.endDate = null;
				this.form.infoStartDate = this.form.infoEndDate = this.$t('aqi.infoDateIntervalDisabled');
				this.loadingAnimation = false;
			}
		},
		inputStartDate() {
			if (this.form.startDate) {
				this.form.endDateDisabled = false;

				if (this.form.endDate) {
					this.submitEndDate(); // better usability
				}
			} else {
				this.form.endDateDisabled = true;
			}
			//this.form.endDate = null;
		},
		submitEndDate() {
			if (this.form.startDate && this.form.endDate) {
				this.loadingAnimation = true;

				if (this.contentTab == this.frequencyTab) {
					this.tableDataLowest = [];
					this.tableDataHighest = [];
				}
				let startDate = new Date(this.form.startDate);
				let endDate = new Date(this.form.endDate);
				let startPlusOne = startDate.getUTCDate() + 1;
				let endPlusOne = endDate.getUTCDate() + 1;
				startDate.setUTCHours(0, 0, 0, 0);
				startDate.setUTCDate(startPlusOne);
				endDate.setUTCHours(23, 59, 59, 0);
				endDate.setUTCDate(endPlusOne);

				this.getSLA(startDate.valueOf(), endDate.valueOf()).then((result) => {
					if (result.length > 0) {
						if (this.contentTab == this.frequencyTab) {
							this.tableDataLowest = this.formatTableData(result, 'asc');
							this.tableDataHighest = this.formatTableData(result, 'desc');
						}
						let slaByArea = this.groupByArea(result);
						let slaByDatasource = this.groupByDatasource(result);
						let slaByDevice = this.groupByDevice(result);
						let slaByDate = this.groupByDate(result);

						let slaByAreaAndDate = [];
						let slaByDatasourceAndDate = [];
						let slaByDeviceAndDate = [];

						slaByArea.forEach((areaGroup) => {
							slaByAreaAndDate.push({
								pmareaid: areaGroup.pmareaid,
								areaname: areaGroup.areaname,
								group: this.groupByDate(areaGroup.group)
							});
						});

						slaByDatasource.forEach((datasourceGroup) => {
							slaByDatasourceAndDate.push({
								pmdatasourcetypeid: datasourceGroup.pmdatasourcetypeid,
								datasourcetypename: datasourceGroup.datasourcetypename,
								group: this.groupByDate(datasourceGroup.group)
							});
						});

						slaByDevice.forEach((deviceGroup) => {
							slaByDeviceAndDate.push({
								pmdatasourceinstanceid: deviceGroup.pmdatasourceinstanceid,
								devicecode: deviceGroup.devicecode,
								group: this.groupByDate(deviceGroup.group)
							});
						});

						this.slaData = slaByDate;
						this.deviceChartData = slaByDeviceAndDate.this.areaChartData = slaByAreaAndDate;
						this.datasourceChartData = slaByDatasourceAndDate;

						this.updateCounters(slaByDate);
						this.loadingAnimation = false;
						if (this.errorTypesData || this.contentTab == this.qualityTab) {
							this.loadingQuality = true;
							this.loadingQuality = true;
							this.retrieveErrorTypes(
								query_errorTypes('validations', startDate.valueOf(), endDate.valueOf()),
								this.$store.getters.getDatasourcetype
							);
						}
					} else {
						this.$puiNotify.info(this.$t('common.apology'), this.$t('aqi.noDataCAMS'));
						this.loadingAnimation = false;
					}
				});
			}
		},
		getFirstDateSLA() {
			return this.compareAqiFirstDates ? this.compareAqiFirstDates : null;
		},
		getLastDateSLA() {
			return this.compareAqiLastDates ? this.compareAqiLastDates : null;
		},

		async getSLA(startDate, endDate = null) {
			let rulesSLA = [
				{ field: 'disabled', op: 'eq', data: 0 },
				{ field: 'datetimestamp', op: 'ge', data: startDate }
			];
			if (endDate) {
				rulesSLA.push({ field: 'datetimestamp', op: 'le', data: endDate });
			}
			if (!isSuperAdmin(this.session.profiles[0])) {
				rulesSLA.push({ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid });
			}

			const body = {
				model: 'pmslacalculations',
				filter: {
					groups: [],
					groupOp: 'and',
					rules: rulesSLA
				},
				rows: 10000
			};

			const { data } = await search(body);
			return data.data;
		},
		groupByDate(dataset) {
			const object = dataset.reduce((r, { datetimestamp, ...rest }) => {
				if (!r[datetimestamp])
					r[datetimestamp] = {
						datevalue: getDateByFormat(datetimestamp, this.userProperties.constants, true),
						datetimestamp,
						group: [rest]
					};
				else r[datetimestamp].group.push(rest);
				return r;
			}, {});
			const result = Object.values(object).sort((a, b) => {
				const timestampA = a.datetimestamp;
				const timestampB = b.datetimestamp;
				if (timestampA < timestampB) {
					return -1;
				}
				if (timestampA > timestampB) {
					return 1;
				}
				return 0;
			});
			return Object.values(result);
		},
		groupByArea(dataset) {
			const sortedDataset = dataset.sort((a, b) => (a.areaname > b.areaname ? 1 : b.areaname > a.areaname ? -1 : 0));
			const groupedDevices = sortedDataset.reduce((r, { pmareaid, areaname, ...rest }) => {
				if (!r[pmareaid]) {
					r[pmareaid] = { pmareaid, areaname, group: [rest] };
				} else {
					r[pmareaid].group.push(rest);
				}
				return r;
			}, {});
			return Object.values(groupedDevices).sort((a, b) => (a.areaname > b.areaname ? 1 : b.areaname > a.areaname ? -1 : 0));
		},
		groupByDatasource(dataset) {
			const groupedDevices = dataset.reduce((r, { pmdatasourcetypeid, datasourcetypename, ...rest }) => {
				if (!r[pmdatasourcetypeid]) {
					r[pmdatasourcetypeid] = { pmdatasourcetypeid, datasourcetypename, group: [rest] };
				} else {
					r[pmdatasourcetypeid].group.push(rest);
				}
				return r;
			}, {});
			return Object.values(groupedDevices).sort((a, b) =>
				a.datasourcetypename > b.datasourcetypename ? 1 : b.datasourcetypename > a.datasourcetypename ? -1 : 0
			);
		},
		groupByDevice(dataset) {
			const groupedDevices = dataset.reduce((r, { pmdatasourceinstanceid, devicecode, ...rest }) => {
				if (!r[pmdatasourceinstanceid]) {
					r[pmdatasourceinstanceid] = { pmdatasourceinstanceid, devicecode, group: [rest] };
				} else {
					r[pmdatasourceinstanceid].group.push(rest);
				}
				return r;
			}, {});
			return Object.values(groupedDevices).sort((a, b) => (a.devicecode > b.devicecode ? 1 : b.devicecode > a.devicecode ? -1 : 0));
		},

		resendQuery() {
			this.loadingAnimation = true;
			if (this.form.dateInterval === -1 && this.form.startDate && this.form.endDate) {
				this.submitEndDate();
			} else {
				if (!this.form.dateInterval) this.form.dateInterval = 7;
				this.submitDateInterval();
			}
		},
		activateGlobalChart() {
			this.contentTab = this.globalTab;
			if (!this.activeGlobal) {
				this.activeGlobal = true;
				this.activeWarning = false;
				this.activeFrequency = false;
				this.activeQuality = false;
				/* this.resendQuery();
				setTimeout(this.resizeChart, 200); */
			}
			this.errorTypesData = null;
		},
		activateWarningPie() {
			this.contentTab = this.warningTab;
			if (!this.activeWarning) {
				this.activeWarning = true;
				this.activeGlobal = this.activeFrequency = this.activeQuality = false;
				//setTimeout(this.resizeChart, 200);
			}
			this.errorTypesData = null;
		},
		activateFrequencyChart() {
			this.contentTab = this.frequencyTab;
			if (!this.activeFrequency) {
				this.activeFrequency = true;
				this.activeGlobal = this.activeWarning = this.activeQuality = false;
			}
			this.errorTypesData = null;
		},
		activateQualityChart() {
			this.contentTab = this.qualityTab;
			if (!this.activeQuality) {
				this.activeQuality = true;
				this.activeGlobal = this.activeWarning = this.activeFrequency = false;
			}
			this.loadingQuality = true;
			if (this.form.dateInterval === -1 && this.form.startDate && this.form.endDate) {
				let startDate = new Date(this.form.startDate);
				let endDate = new Date(this.form.endDate);
				let startPlusOne = startDate.getUTCDate() + 1;
				let endPlusOne = endDate.getUTCDate() + 1;
				startDate.setUTCHours(0, 0, 0, 0);
				startDate.setUTCDate(startPlusOne);
				endDate.setUTCHours(23, 59, 59, 0);
				endDate.setUTCDate(endPlusOne);
				this.retrieveErrorTypes(
					query_errorTypes('validations', startDate.valueOf(), endDate.valueOf()),
					this.$store.getters.getDatasourcetype
				);
			} else {
				let d = new Date();
				d.setUTCDate(d.getUTCDate() - this.form.dateInterval);
				d.setUTCHours(0, 0, 0, 0);
				this.retrieveErrorTypes(query_errorTypes('validations', d.valueOf(), Date.now()), this.$store.getters.getDatasourcetype);
			}
		},
		async retrieveErrorTypes(query, datasources) {
			let d = new Date();
			d.setUTCDate(d.getUTCDate() - this.form.dateInterval);
			d.setUTCHours(0, 0, 0, 0);

			if (datasources.length > 0) {
				let promises = [];
				datasources.forEach((datasource) => {
					const index = obtainIndexName(datasource);
					if (index) promises.push(getErrorTypes(obtainIndexName(datasource) + constants.ELASTIC_ERROR_SUFFIX, query));
				});
				Promise.all(promises).then((results) => {
					const filteredValidations = results.filter((result) => typeof result.message == 'object').map((result) => result.message);
					console.log('getErrorTypes promises', results, filteredValidations);
					const mergeErrorTypes = (data) => {
						return data.reduce((acc, curr) => {
							// Iterate through the date keys in the current object
							for (let date in curr) {
								// If the date exists in the accumulator, merge the nested objects
								if (acc[date]) {
									for (let key in curr[date]) {
										if (acc[date][key]) {
											acc[date][key] += curr[date][key]; // Add the values for matching keys
										} else {
											acc[date][key] = curr[date][key]; // Add the key if it doesn't exist
										}
									}
								} else {
									acc[date] = { ...curr[date] }; // If the date doesn't exist, add the entire object
								}
							}
							return acc;
						}, {});
					};
					const mergedErrorTypes = mergeErrorTypes(filteredValidations);
					if (Object.keys(mergedErrorTypes).length == 0) {
						this.errorTypesData = null;
						this.$puiNotify.info(this.$t('aqi.noDataLastSelection'));
					} else this.errorTypesData = mergedErrorTypes;
					this.loadingQuality = false;
					this.form.option = this.getCurrentOption();
				});
			}
		},

		async formatIntervals() {
			this.intervals = this.intervals.map((interval) => {
				if (interval.value !== -1) {
					let date = new Date();
					let dateMinusInterval = new Date();
					dateMinusInterval.setUTCDate(dateMinusInterval.getUTCDate() - interval.value);
					date.setUTCDate(date.getUTCDate() - 1);
					let startDate = getDateByFormat(dateMinusInterval.valueOf(), this.userProperties.constants, true);
					let endDate = getDateByFormat(date.valueOf(), this.userProperties.constants, true);

					return { text: interval.text + ' (' + startDate + ' - ' + endDate + ')', value: interval.value };
				}
				return interval;
			});
		},
		getHeaders() {
			let slaKeys = ['devicecode', 'deliveryperiod', 'docsexpectedbyfrequency', 'docstotal', 'datetimestamp'];

			return slaKeys.map((key) => {
				return { text: this.$t('panels.dashboard.table.' + key), value: key };
			});
		},
		formatTableData(slaItems, option = 'desc') {
			let mappedSla = slaItems
				.map((sla) => {
					if (sla.docsexpectedbyfrequency > 0) {
						let frequency = (((sla.docsok + sla.docserror) / sla.docsexpectedbyfrequency) * 100).toFixed(2);
						return {
							docstotal: sla.docsok + sla.docserror,
							frequency: parseFloat(frequency),
							devicecode: sla.devicecode,
							docsexpectedbyfrequency: sla.docsexpectedbyfrequency,
							pmslacalculationsid: sla.pmslacalculationsid,
							deliveryperiod: sla.deliveryperiod + ' ' + sla.timeunitacronym,
							datetimestamp: sla.datetimestamp
						};
					} else return null;
				})
				.filter((sla) => sla !== null);
			let sortedSla = null;
			if (option == 'desc') {
				sortedSla = mappedSla.sort((a, b) => {
					if (a.frequency > b.frequency) {
						return -1;
					}
					if (a.frequency < b.frequency) {
						return 1;
					}
					return 0;
				});
			} else {
				sortedSla = mappedSla.sort((a, b) => {
					if (a.frequency < b.frequency) {
						return -1;
					}
					if (a.frequency > b.frequency) {
						return 1;
					}
					return 0;
				});
			}

			let topItems = {};
			console.log('sorted sla items ', sortedSla);

			sortedSla.forEach((sla) => {
				if (!Object.hasOwn(topItems, [sla.devicecode])) {
					topItems[sla.devicecode] = sla;
				}
			});
			console.log('debug sla items ', option, mappedSla, topItems, Object.values(topItems));
			return Object.values(topItems).sort((a, b) => {
				const elementA = a.devicecode.toUpperCase();
				const elementB = b.devicecode.toUpperCase();
				return elementA.localeCompare(elementB);
			});
		},
		formatDate(timestamp) {
			return getDateByFormat(timestamp, this.userProperties.constants, true);
		},
		getCurrentOption() {
			let option = null;
			switch (this.contentTab) {
				case this.globalTab:
					switch (this.globalFilterTab) {
						case 'filter-global':
							if (this.slaData) option = this.initChart(this.slaData);
							this.chartRef = 'chart-sla-global';
							this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.SLA + '_' + constants.DASHBOARD.GLOBAL;
							break;
						case 'filter-area':
							if (this.areaChartData) option = this.initChartGroup(this.areaChartData, 'area');
							this.chartRef = 'chart-sla-area';
							this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.SLA + '_' + constants.DASHBOARD.AREA;

							break;
						case 'filter-datasource':
							if (this.datasourceChartData) option = this.initChartGroup(this.datasourceChartData, 'datasource');
							this.chartRef = 'chart-sla-datasource';
							this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.SLA + '_' + constants.DASHBOARD.DATASOURCE;

							break;
						case 'filter-device':
							if (this.deviceChartData) option = this.initChartDevice(this.deviceChartData);
							this.chartRef = 'chart-sla-device';
							this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.SLA + '_' + constants.DASHBOARD.DEVICE;

							break;
					}
					break;
				case this.warningTab:
					if (this.warningFilterTab == 'filter-warning') {
						if (this.slaData) option = this.initChartWarning(this.slaData);
						this.chartRef = 'chart-warning';
						this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.WARNING;
					} else {
						if (this.slaData) option = this.initChartWarningType(this.slaData);
						this.chartRef = 'chart-warning-type';
						this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.WARNING + '_' + constants.DASHBOARD.TYPES;
					}
					break;
				case this.frequencyTab:
					switch (this.frequencyFilterTab) {
						case 'frequency-global':
							if (this.slaData) option = this.initChartFrequency(this.slaData);
							this.chartRef = 'chart-frequency';
							this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.FREQUENCY + '_' + constants.DASHBOARD.GLOBAL;

							break;
						case 'frequency-area':
							if (this.selectedArea) {
								option = this.initChartFrequencyArea(
									this.areaChartData.find((area) => area.pmareaid == this.selectedArea.value),
									this.$store.getters.getAreas.find((area) => area.pmareaid == this.selectedArea.value)
								);
								this.chartRef = 'chart-frequency-area';
								this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.FREQUENCY + '_' + constants.DASHBOARD.AREA;
							}

							break;
						case 'frequency-datasource':
							if (this.selectedDatasource) {
								option = this.initChartFrequencyDatasource(
									this.datasourceChartData.find((datasource) => datasource.pmdatasourcetypeid == this.selectedDatasource.value),
									this.$store.getters.getDatasourcetype.find(
										(datasource) => datasource.pmdatasourcetypeid == this.selectedDatasource.value
									)
								);
								this.chartRef = 'chart-frequency-datasource';
								this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.FREQUENCY + '_' + constants.DASHBOARD.DATASOURCE;
							}

							break;
						case 'frequency-device':
							if (this.selectedDevice) {
								option = this.initChartFrequencyDevice(
									this.deviceChartData.find((device) => device.pmdatasourceinstanceid == this.selectedDevice.value),
									this.$store.getters.getDevicesData.find((device) => device.pmdatasourceinstanceid == this.selectedDevice.value)
								);
								this.chartRef = 'chart-frequency-device';
								this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.FREQUENCY + '_' + constants.DASHBOARD.DEVICE;
							}
							break;
						case 'frequency-analysis':
							option = null;
							this.chartInstance = null;
							this.chartRef = null;
							this.form.designOption.name = 'DASHBOARD';

							break;
					}
					break;
				case this.qualityTab:
					if (this.errorTypesData) {
						option = this.initChartDataQuality(this.errorTypesData);
						this.chartRef = 'chart-error-type';
						this.form.designOption.name = 'DASHBOARD_' + constants.DASHBOARD.ERROR + '_' + constants.DASHBOARD.TYPES;
					}
					break;
			}
			if (this.chartRef)
				setTimeout(() => {
					this.chartInstance = this.$refs[this.chartRef];
					console.log('ref set', this.chartRef, this.chartInstance);
				}, 200);
			console.log('current option debug', this.contentTab, option, this.$refs, this.chartRef, this.chartInstance);
			return option;
		}
	}
};
</script>

<style lang="postcss">
.sla-global-content {
	& .dynamic-btn {
		transition: background-color 0.5s ease;
	}
	& .chart-no-tabs {
		height: calc(100vh - 287px);
		width: 100%;
	}
	& .chart-with-tabs {
		height: calc(100vh - 316px);
		width: 100%;
	}
	& .chart-with-tabs-plus {
		height: calc(100vh - 370px);
		width: 100%;
	}
	& .v-data-footer {
		height: 56px;
		& .v-data-footer__select {
			height: 56px;
		}
	}
}

@media only screen and (min-width: 600px) {
	.responsive-height {
		height: 64px;
	}
}
</style>
