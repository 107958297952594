<template>
	<div class="d-flex flex-wrap width-100 ml-n6">
		<v-list-item-title class="fw-bold fs-regular color-high d-flex justify-space-between ml-n4">
			<span>{{ orgItem.organizationname }}</span>
			<div>
				<span v-if="Object.hasOwn(totalsOrg, orgItem.pmorganizationid)"> {{ totalsOrg[orgItem.pmorganizationid].toFixed(2) }}% </span>
				<div v-else>
					<v-tooltip right max-width="350" transition="scroll-x-transition" color="00000099">
						<template v-slot:activator="{ on, attrs }">
							<v-icon v-bind="attrs" v-on="on" size="14" class="fw-500 pr-1"> fak fa-solid-anomalies </v-icon>
						</template>
						<span class="fw-500 fs-regular text-center">{{ $t('common.noData') }}</span>
					</v-tooltip>
					<!-- <span class="color-high"> - %</span> -->
				</div>
			</div>
		</v-list-item-title>
		<v-list-item-subtitle class="ml-n4">
			<kpi-bar :sections="sectionsOrgs[orgItem.pmorganizationid] ? sectionsOrgs[orgItem.pmorganizationid] : []" barHeight="8px"></kpi-bar>
		</v-list-item-subtitle>
	</div>
</template>

<script>
import KpiBar from './KpiBar.vue';
export default {
	components: {
		KpiBar
	},
	props: {
		orgItem: {
			type: Object,
			required: true
		},
		sectionsOrgs: {
			type: Object,
			required: true
		},
		totalsOrg: {
			type: Object,
			required: true
		}
	}
};
</script>

<style></style>
