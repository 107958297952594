<template>
	<v-tooltip right color="00000099" class="kpibarsection">
		<template v-slot:activator="{ on }">
			<div
				class="sectionbar"
				:style="{ width: percent + '% !important', background: color, height: barHeight }"
				@click="$listeners.click"
				v-on="on"
			/>
		</template>
		<span class="tooltip d-flex align-center">
			<div class="tipicon pr-3">
				<div :style="'background: ' + color"></div>
			</div>
			<div class="tiptext flex-grow-1">{{ title }}</div>
			<div class="pl-5">
				<strong>{{ value }}</strong>
			</div>
		</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'KpiBarItem',

	props: {
		value: {
			type: Number,
			required: true
		},

		disabled: {
			type: Boolean,
			default: false
		},

		color: {
			type: String,
			required: true
		},

		title: {
			type: String,
			required: true
		},

		percent: {
			type: Number,
			required: true
		},
		barHeight: {
			default: '6px'
		}
	},
	data() {
		return {
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../styles/eiffel-variables.pcss';

.sectionbar {
	padding: 0px;
	margin: 0px;
	transition: width 0.3s ease;

	&:hover {
		cursor: pointer;
		border-left: 1px solid var(--negative) !important;
		border-right: 1px solid var(--negative) !important;
		filter: brightness(120%);
	}
	&.disabled {
		background: var(--moderate) !important;
		border-left: 0px solid var(--moderate) !important;
		&:hover {
			background: var(--moderate) !important;
			border-left: 0px solid var(--moderate) !important;
		}
	}
}

.tooltip {
	& .tipicon {
		* {
			width: 10px !important;
			height: 10px !important;
			border-radius: 10px;
		}
	}
}
</style>
