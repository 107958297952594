import { render, staticRenderFns } from "./KpiBarSection.vue?vue&type=template&id=585a59bc&scoped=true"
import script from "./KpiBarSection.vue?vue&type=script&lang=js"
export * from "./KpiBarSection.vue?vue&type=script&lang=js"
import style0 from "./KpiBarSection.vue?vue&type=style&index=0&id=585a59bc&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "585a59bc",
  null
  
)

export default component.exports